import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { roundAndFix, removeVatFrom } from '../scripts/helpers'
import { PageTemplate } from './page'
import Img from 'gatsby-image'
import FsLightbox from "fslightbox-react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SiteMetadata from '../components/SiteMetadata'
import { add } from 'cart-localstorage'
import Cta from '../components/shortcodes/Cta'
import FaqSection from '../components/shortcodes/FaqSection'
import ProductPageStyles from '../styles/ProductPage.module.sass'
import RentalOptionsStyles from '../styles/RentalOptions.module.sass'

/**
 * Create Product Template Component
 */
const Product = ({ data }) => {
  const { maxProductQuantity } = SiteMetadata()

  const [rentalOptions, setRentalOptions] = useState(
    data.mdx.frontmatter.purchaseType === 'rent' ? (
      {
        months: 3,
        discountPercent: data.mdx.frontmatter.rentalPrices[1].discountPercent,
        price: data.mdx.frontmatter.rentalPrices[1].price,
      }
    ) : null
  )

  const [rentalTotal, setRentalTotal] = useState(
    data.mdx.frontmatter.purchaseType === 'rent' ? (
      (rentalOptions.months * rentalOptions.price) + (data.mdx.frontmatter.kitPrice || 0)
    ) : null
  )

  const [rentalOptionsDropdownOpen, setRentalOptionsDropdownOpen] = useState(false)

  const updateRentalOptions = (options) => {
    // Close dropdown
    setRentalOptionsDropdownOpen(false)

    // Set rental options
    setRentalOptions({
      months: options[0],
      discountPercent: options[1],
      price: options[2]
    })

    // Update total price
    setRentalTotal(
      options[0] * options[2] + (data.mdx.frontmatter.kitPrice || 0)
    )

    document.querySelector('.anchor-above-add').scrollIntoView()
  }

  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)

  const price = roundAndFix(data.mdx.frontmatter.price)
  const adjustedPrice = price.toString().split('.')

  const priceWithoutVat = removeVatFrom(price)

  // const amountVat = roundAndFix(price - priceWithoutVat)

  function addToOrderAction(id, title, price, quantity) {
    console.log(id, title, price, quantity)

    add({ id: id, name: title, price: price }, quantity)

    // Reload the page to update the floating cart
    // TODO: Research using Redux for this purpose in the future
    window.location.href = '/checkout'
  }

  const [qty, setQty] = useState(1)

  // Increase/Decrease quantity using buttons
  function changeQty(operation) {
    // Prevent decreasing to 0 or increasing above maxProductQuantity
    if (
      (qty <= 1 && operation === 'decrease') ||
      (qty >= maxProductQuantity && operation === 'increase')
    ) {
      return false
    }

    // Clone and change quantities
    if (operation === 'decrease') {
      setQty(~~qty - 1)
    } else {
      setQty(~~qty + 1)
    }
  }

  // Increase/Decrease quantity using number input
  function inputQty(input) {
    if (input === '' || (~~input > 0 && ~~input <= maxProductQuantity)) {
      setQty( ~~input )
    }
    else if (input < 1) {
      setQty(1)
    }
    else if (input > maxProductQuantity) {
      setQty(maxProductQuantity)
    }
  }

  return (
    <PageTemplate
      pageClass={`${data.mdx.frontmatter.pageClass || ''} ${ProductPageStyles.page || ''} hide-cart-if-empty wave-on-top footer-dimmed`}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      showCta={false}
      showContactForm={false}
      title={data.mdx.frontmatter.fullTitle || data.mdx.frontmatter.title}
      notNarrow={true}
    >
      {/* Columns */}
      <div className={`columns is-desktop ${ProductPageStyles.columnsContainer || ''}`}>

        {/* Product Image */}
        <div className={`column ${ProductPageStyles.mainImageHolder || ''}`}>
          {data.mdx.frontmatter.image ? (
            data.mdx.frontmatter.image.childImageSharp ? (
              <div className={ProductPageStyles.mainImage || ''}>
                <Img
                  fluid={data.mdx.frontmatter.image.childImageSharp.fluid}
                  alt={data.mdx.frontmatter.title || ''}
                  title={data.mdx.frontmatter.title || ''}
                />
              </div>
            ) : (
              <div className={ProductPageStyles.mainImage || ''}>
                <img
                  src={data.mdx.frontmatter.image}
                  alt={data.mdx.frontmatter.title || ''}
                  title={data.mdx.frontmatter.title || ''}
                />
              </div>
            )
          ) : null}

          {/* Image Gallery */}
          {data.mdx.frontmatter.extraImages && data.mdx.frontmatter.extraImages[0] && (
            <div className={`columns is-mobile ${ProductPageStyles.gallery || ''}`}>
              {data.mdx.frontmatter.image ? (
                data.mdx.frontmatter.image.childImageSharp ? (
                  <div className={`column ${ProductPageStyles.galleryImage || ''}`} onClick={() => setModal1(!modal1)}>
                    <Img
                      fluid={data.mdx.frontmatter.image.childImageSharp.fluid}
                      alt={data.mdx.frontmatter.title || ''}
                      title={data.mdx.frontmatter.title || ''}
                    />
                  </div>
                ) : (
                    <div className={`column ${ProductPageStyles.galleryImage || ''}`} onClick={() => setModal1(!modal1)}>
                    <img
                      src={data.mdx.frontmatter.image}
                      alt={data.mdx.frontmatter.title || ''}
                      title={data.mdx.frontmatter.title || ''}
                    />
                  </div>
                )
              ) : null}

              <FsLightbox toggler={modal1} sources={[ data.mdx.frontmatter.image.publicURL ]}/>

              {data.mdx.frontmatter.extraImages && data.mdx.frontmatter.extraImages[0] ? (
                data.mdx.frontmatter.extraImages[0].childImageSharp ? (
                  <div className={`column ${ProductPageStyles.galleryImage || ''}`} onClick={() => setModal2(!modal2)}>
                    <Img
                      key={data.mdx.frontmatter.extraImages[0].publicURL}
                      fluid={data.mdx.frontmatter.extraImages[0].childImageSharp.fluid}
                      alt={data.mdx.frontmatter.title || ''}
                      title={data.mdx.frontmatter.title || ''}
                    />
                  </div>
                ) : (
                  <div className={`column ${ProductPageStyles.galleryImage || ''}`} onClick={() => setModal2(!modal2)}>
                    <img
                      key={data.mdx.frontmatter.extraImages[0].publicURL}
                      src={data.mdx.frontmatter.extraImages[0]}
                      alt={data.mdx.frontmatter.title || ''}
                      title={data.mdx.frontmatter.title || ''}
                    />
                  </div>
                )
              ) : null}

              <FsLightbox toggler={modal2} sources={[data.mdx.frontmatter.extraImages[0].publicURL]} />

              {data.mdx.frontmatter.extraImages && data.mdx.frontmatter.extraImages[1] ? (
                data.mdx.frontmatter.extraImages[1].childImageSharp ? (
                  <div className={`column ${ProductPageStyles.galleryImage || ''}`} onClick={() => setModal3(!modal3)}>
                    <Img
                      key={data.mdx.frontmatter.extraImages[1].publicURL}
                      fluid={data.mdx.frontmatter.extraImages[1].childImageSharp.fluid}
                      alt={data.mdx.frontmatter.title || ''}
                      title={data.mdx.frontmatter.title || ''}
                    />
                  </div>
                ) : (
                  <div className={`column ${ProductPageStyles.galleryImage || ''}`} onClick={() => setModal3(!modal3)}>
                    <img
                      key={data.mdx.frontmatter.extraImages[1].publicURL}
                      src={data.mdx.frontmatter.extraImages[1]}
                      alt={data.mdx.frontmatter.title || ''}
                      title={data.mdx.frontmatter.title || ''}
                    />
                  </div>
                )
              ) : null}

              {data.mdx.frontmatter.extraImages && data.mdx.frontmatter.extraImages[1] && <FsLightbox toggler={modal3} sources={[data.mdx.frontmatter.extraImages[1].publicURL]} />}
            </div>
          )}
        </div>

        {/* Vitals */}
        <div className={`column ${ProductPageStyles.vitalsHolder || ''}`}>
          {/* Short Description */}
          <p 
            className={ProductPageStyles.shortDescription || ''}
            dangerouslySetInnerHTML={{ __html: data.mdx.frontmatter.shortDescription }}
          />

          {/* Kit Contents */}
          {data.mdx.frontmatter.kitContents ? (
            <>
              <strong className={`mini-title ${ProductPageStyles.miniTitle || ''}`}>
                Please note that every rental has a charge of &euro;{data.mdx.frontmatter.kitPrice} for a non-returnable starter kit containing:
              </strong>

              <ul className='fancy-list'>
                {data.mdx.frontmatter.kitContents.map((item, i) => {
                  return <li key={item + i}>{item}</li>
                })}
              </ul>
            </>
          ) : null}

          {/* Fancy List */}
          {data.mdx.frontmatter.fancyList ? (
            <>
              {data.mdx.frontmatter.fancyListHeading ? <p><b>{data.mdx.frontmatter.fancyListHeading}</b></p> : ''}
              <ul className='fancy-list'>
                {data.mdx.frontmatter.fancyList.map((item, i) => {
                  return <li key={item + i}>{item}</li>
                })}
              </ul>
            </>
          ) : null}

          {/* Add to Order */}
          <span className="anchor-above-add"></span>
          <div className={ProductPageStyles.addToOrder || ''} data-purchase-type={data.mdx.frontmatter.purchaseType}>
            {/* Simple Product Actions */}
            {data.mdx.frontmatter.purchaseType === 'sell' && (
              <>
                {/* Price Breakdown */}
                <div className={ProductPageStyles.prices || ''}>
                  <ul className={ProductPageStyles.priceList || ''}>
                    <li className={ProductPageStyles.total || ''}>
                      <i>&euro;</i><b><span>{adjustedPrice[0]}</span><small>{adjustedPrice[1]}</small></b>
                    </li>
                  </ul>
                </div>

                {/* Quantity Selector & Add to Order Button */}
                <div className={ProductPageStyles.actions || ''}>
                  <div className={ProductPageStyles.quantityWidget || ''}>
                    <input
                      type="text"
                      name="quantity"
                      value={qty}
                      onChange={e => {
                        inputQty(e.target.value)
                      }}
                    />
                    <span
                      className={`tag ${ProductPageStyles.increase || ''}`}
                      onClick={() => {
                        changeQty('increase')
                      }}
                      onKeyDown={() => {
                        changeQty('increase')
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      +
                    </span>
                    <span
                      className={`tag ${ProductPageStyles.decrease || ''}`}
                      onClick={() => {
                        changeQty('decrease')
                      }}
                      onKeyDown={() => {
                        changeQty('decrease')
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      –
                    </span>
                  </div>

                  <button
                    className={`button is-link is-strong ${ProductPageStyles.addToOrderButton || ''}`}
                    disabled={data.mdx.frontmatter.stockStatus === 'outOfStock' ? true : false}
                    onClick={() => {
                      addToOrderAction(
                        data.mdx.frontmatter.productID,
                        data.mdx.frontmatter.fullTitle,
                        data.mdx.frontmatter.price,
                        qty
                      )
                    }}
                  >
                    {data.mdx.frontmatter.stockStatus === 'outOfStock' ? 'Out of Stock' : 'Add to Order'}
                  </button>
                </div>
              </>
            )}

            {/* Rental Product Actions */}
            {data.mdx.frontmatter.purchaseType === 'rent' && (
              <>
                <em>Choose Rental Duration</em>
                <div className={RentalOptionsStyles.rentalOptions || ''} data-dropdown-state={rentalOptionsDropdownOpen}>
                  <div className={RentalOptionsStyles.rentalDuration || ''} onClick={() => setRentalOptionsDropdownOpen(!rentalOptionsDropdownOpen)}>
                    {rentalOptionsDropdownOpen && <span className={RentalOptionsStyles.concealer || ''} onClick={() => setRentalOptionsDropdownOpen(false)}></span>}

                    <span className={RentalOptionsStyles.months || ''}>{rentalOptions.months} {rentalOptions.months === 1 ? 'month' : 'months'}</span>

                    {rentalOptions.months > 1 && (
                      <span className={RentalOptionsStyles.price || ''}>
                        &euro;{rentalOptions.price}/mo
                      </span>
                    )}

                    {
                      rentalOptions.discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{rentalOptions.discountPercent}% off</span>
                    }
                  </div>
                  <ul className={RentalOptionsStyles.rentalDropdown || ''} style={{display: rentalOptionsDropdownOpen ? 'block' : 'none'}}>
                    <li onClick={() => updateRentalOptions([1, data.mdx.frontmatter.rentalPrices[0].discountPercent, data.mdx.frontmatter.rentalPrices[0].price])}>
                      <span className={RentalOptionsStyles.months || ''}>1 month</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[0].price}{RentalOptionsStyles.months > 1 && '/mo'}</span>
                      {
                        data.mdx.frontmatter.rentalPrices[0].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[0].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([2, data.mdx.frontmatter.rentalPrices[0].discountPercent, data.mdx.frontmatter.rentalPrices[0].price])}>
                      <span className={RentalOptionsStyles.months || ''}>2 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[0].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[0].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[0].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([3, data.mdx.frontmatter.rentalPrices[1].discountPercent, data.mdx.frontmatter.rentalPrices[1].price])}>
                      <span className={RentalOptionsStyles.months || ''}>3 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[1].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[1].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[1].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([4, data.mdx.frontmatter.rentalPrices[1].discountPercent, data.mdx.frontmatter.rentalPrices[1].price])}>
                      <span className={RentalOptionsStyles.months || ''}>4 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[1].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[1].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[1].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([5, data.mdx.frontmatter.rentalPrices[1].discountPercent, data.mdx.frontmatter.rentalPrices[1].price])}>
                      <span className={RentalOptionsStyles.months || ''}>5 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[1].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[1].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[1].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([6, data.mdx.frontmatter.rentalPrices[2].discountPercent, data.mdx.frontmatter.rentalPrices[2].price])}>
                      <span className={RentalOptionsStyles.months || ''}>6 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[2].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[2].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[2].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([7, data.mdx.frontmatter.rentalPrices[2].discountPercent, data.mdx.frontmatter.rentalPrices[2].price])}>
                      <span className={RentalOptionsStyles.months || ''}>7 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[2].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[2].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[2].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([8, data.mdx.frontmatter.rentalPrices[2].discountPercent, data.mdx.frontmatter.rentalPrices[2].price])}>
                      <span className={RentalOptionsStyles.months || ''}>8 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[2].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[2].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[2].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([9, data.mdx.frontmatter.rentalPrices[3].discountPercent, data.mdx.frontmatter.rentalPrices[3].price])}>
                      <span className={RentalOptionsStyles.months || ''}>9 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[3].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[3].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[3].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([10, data.mdx.frontmatter.rentalPrices[3].discountPercent, data.mdx.frontmatter.rentalPrices[3].price])}>
                      <span className={RentalOptionsStyles.months || ''}>10 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[3].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[3].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[3].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([11, data.mdx.frontmatter.rentalPrices[3].discountPercent, data.mdx.frontmatter.rentalPrices[3].price])}>
                      <span className={RentalOptionsStyles.months || ''}>11 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[3].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[3].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[3].discountPercent}% off</span>
                      }
                    </li>
                    <li onClick={() => updateRentalOptions([12, data.mdx.frontmatter.rentalPrices[3].discountPercent, data.mdx.frontmatter.rentalPrices[3].price])}>
                      <span className={RentalOptionsStyles.months || ''}>12 months</span>
                      <span className={RentalOptionsStyles.price || ''}>&euro;{data.mdx.frontmatter.rentalPrices[3].price}/mo</span>
                      {
                        data.mdx.frontmatter.rentalPrices[3].discountPercent > 0 && <span className={RentalOptionsStyles.discountPercent || ''}>{data.mdx.frontmatter.rentalPrices[3].discountPercent}% off</span>
                      }
                    </li>
                  </ul>
                </div>

                {/* Total & Add to Order Button */}
                <div className={ProductPageStyles.actions || ''}>
                  {/* Total */}
                  <div className={ProductPageStyles.prices || ''}>
                    <ul className={ProductPageStyles.priceList || ''}>
                      <li className={ProductPageStyles.total || ''}>
                        <i>&euro;</i><b><span>{rentalTotal}</span><small></small></b>
                      </li>
                    </ul>
                  </div>

                  {/* Add to Order Button */}
                  <button
                    className={`button is-link is-strong ${ProductPageStyles.addToOrderButton || ''}`}
                    disabled={data.mdx.frontmatter.stockStatus === 'outOfStock' ? true : false}
                    onClick={() => {
                      addToOrderAction(
                        data.mdx.frontmatter.productID + '_' + rentalOptions.months + '_' + rentalTotal + '_' + Date.now(),
                        data.mdx.frontmatter.fullTitle,
                        rentalTotal,
                        1
                      )
                    }}
                  >
                    {data.mdx.frontmatter.stockStatus === 'outOfStock' ? 'Out of Stock' : 'Add to Order'}
                  </button>

                  <p className={ProductPageStyles.smallNote || ''}>Free nationwide delivery. Includes &euro;{data.mdx.frontmatter.kitPrice} for a non-returnable starter kit.</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Tabs Section */}
      {data.mdx.frontmatter.tabs && (
        <div className={`force-full-width ${ProductPageStyles.tabsHolder || ""}`}>
          <Tabs className={ProductPageStyles.tabs || ''} data-single-tab={(data.mdx.frontmatter.tabs && data.mdx.frontmatter.tabs[1]) ? false : true}>
            <TabList>
              {data.mdx.frontmatter.tabs.map((item) => {
                return <Tab>{item.title}</Tab>
              })}
            </TabList>
            
            <div className="container is-narrow">
              {data.mdx.frontmatter.tabs.map((item) => {
                return (
                  <TabPanel>
                    <h2 className="title is-4">{item.title}</h2><div dangerouslySetInnerHTML={{ __html: item.content }} />

                    {item.hasFaq && <FaqSection />}
                  </TabPanel>
                )
              })}
            </div>
          </Tabs>
        </div>
      )}

      <div className={`container is-narrow ${ProductPageStyles.belowProduct || ''}`}>
        <Cta isPhone='true' before="Do you have questions or need advice?" className="is-tertiary low-mb" />
      </div>

      {/* Schema */}
      {data.mdx.frontmatter.schema ?
        <script type="application/ld+json">
          {data.mdx.frontmatter.schema}
        </script>
        : null
      }
    </PageTemplate>
  )
}

export default Product

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      fields {
        pageIdentifier
        slug
      }
      frontmatter {
        fancyList
        fancyListHeading
        kitContents
        kitPrice
        price
        productType
        productID
        purchaseType
        rentalPrices {
          discountPercent
          price
        }
        schema
        shortDescription
        stockStatus
        tabs {
          title
          content
          hasFaq
        }
        title
        fullTitle
        image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        extraImages {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`